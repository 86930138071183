.l-footer {
  width: 100%;
  border-top: solid 1px $c_border;
  &__pagetop {
    line-height: 1;
    color: $c_text;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 9;
    transition: 0.3s;
    &__icon {
      display: block;
      width: 44px;
      height: 44px;
      margin: 0 auto 10px;
      border-radius: 8px;
      background: rgba(29, 29, 29, 0.5);
      position: relative;
      @extend .lc-icon;
      @extend .lc-icon--arrowUp;
      &::before {
        color: $c_base;
        font-size: 3.2rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .is-pageTopHide &,
    .is-scrollUp & {
      opacity: 0;
      visibility: hidden;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px;
  }
  &__contact {
    display: flex;
    align-items: center;
    text-align: right;
  }
  &__inquiry {
    margin-right: 60px;
    > .snsLinks {
      justify-content: flex-end;
    }
  }
  &__address {
    margin-bottom: 10px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  &__copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: $c_main;
    color: $c_base;
    font-size: 1rem;
  }
  @include media(tab) {
    &__pagetop {
      right: 15px;
      bottom: 30px;
    }
    &__inner {
      flex-direction: column;
      padding: 15px;
    }
    &__navi {
      margin-bottom: 15px;
    }
    &__contact {
      flex-direction: column-reverse;
      text-align: center;
    }
    &__inquiry {
      margin-right: 0;
      > .snsLinks {
        justify-content: center;
      }
    }
    &__logo {
      width: 60px;
    }
    &__address {
      margin-top: 15px;
    }
  }
}

.footerPaymentMethods {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-bottom: solid 1px $c_border;
  line-height: 1;
  &__item {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-left: 64px;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 24px;
        background: $c_accent;
        position: absolute;
        left: -32px;
        top: 50%;
        transform: translate(-50%, -50%) skew(-24deg);
      }
    }
  }
  &__label {
    margin-right: 30px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.25;
  }
  &__detail {
    display: flex;
  }
  &__method {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
    > img {
      width: auto;
      height: 20px;
    }
  }
  @include media(tab) {
    flex-direction: column;
    height: auto;
    padding: 15px;
    &__item {
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 15px;
        &::before {
          display: none;
        }
      }
    }
    &__label {
      margin-right: 15px;
      font-size: 1.2rem;
    }
    &__method {
      margin-left: 10px;
      > img {
        height: 15px;
      }
    }
  }
}

.footerNavi {
  font-size: 1.4rem;
  &__section {
    margin-top: 15px;
  }
  &__label {
    margin-bottom: 5px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  &__item {
    display: inline-block;
    &::before {
      content: '/';
      display: inline-block;
      margin: 0 1.5em;
    }
    &:first-of-type {
      &::before {
        display: none;
      }
    }
    a {
      @include media(tab-min) {
        &:hover {
          color: $c_main;
          opacity: 1;
        }
      }
    }
  }
  @include media(tab) {
    text-align: center;
    &__item {
      &::before {
        margin: 0 1em;
      }
    }
  }
}